.Navigator
	width: calc( 100vw - 10px )
	height: calc( 100vh - 80px )
	flex-direction: row
	justify-content: space-between

	.content
		scrollbar-color: white #1C2333
		scrollbar-width: thin

	&.linking
		cursor: crosshair

		.ItemWrapper:hover
			opacity: 1 !important
	.Body
		display: flex
		flex-direction: row
		height: calc( 100vh - 88px - 56px - 2rem)
		flex-shrink: 1
		justify-content: center


	.GaugeWrapper
		position: absolute !important
		top : 70px
		right: 15px
		width: 390px
		height: 220px
		display: flex
		justify-content: center

		svg
			width: 390px
			height: 220px

		&.bigGauge
			height: 390px
			width: 390px
			top : 90px
			right: 35px
			svg
				
				width: 80%
				height: 80%

	#AddItem
		display: none
		position: absolute
		left: calc( 300px + 1em )
		top: calc( 144px + 1em )

	#Goal
		position: absolute
		bottom: 10px
		left: 10px

	.VulnerabilityScale
		margin: 1rem 1rem 1rem 2rem
		
	.tag
		display: inline-block
		border-color: transparent 
		cursor: pointer
		margin-bottom: 0.25rem
		align-items: center
		display: inline-flex


		&.unselected
			border: 1px solid #A6B9C844
			background: none !important
			color: #A6B9C8

		svg
			margin-top: 4px !important

		.Weight
			display: inline-flex
			>div
				height: 10px
				width: 8px
				border: 1px solid #A6B9C8
				background: white
				&.filled
					background: #1C2333
					border: 1px solid #1C2333

				&:first-child
					margin-left: 5px

			>div+div
				margin-left: 1px

		&.ImpactedIndicator, &.ImpactedConstraint

			.Trend
				border-radius: 8px
				height: 8px
				width: 8px
				margin-right: 5px
				background: white
						
				&.red
					background: #E70549
				&.orange
					background: #93586A
				&.white
					background: #FFFFFF
				&.blue
					background: #4A6868
				&.green
					background: #24CCB8

				&.v50
					background: linear-gradient(90deg,  #E70549 0%, #E70549 50%, #24CCB8 50%, #24CCB8 100%) !important
