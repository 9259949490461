@import url('https://fonts.googleapis.com/css2?family=Public+Sans&display=swap')

html
	overflow: hidden
	height: 100%
	position: fixed

body
	overflow: auto
	height: 100%
	position: relative


#App
	min-height: 100vh
	background: #F0F5FA
	transition: 1s background

	&.dark
		background: rgb(19, 19, 19)

	.Layout
		background: none
*
	font-family: 'Public Sans', sans-serif
	font-weight: 300

ul
	list-style-type: none
	margin: 0
	padding: 0

.ant-btn

.ant-btn.ant-btn-primary
	background: #AF2318
	border: none
	color: white
	box-shadow: none

	&:hover
		background: #AF2318DD

.noselection
	-webkit-touch-callout: none
	-webkit-user-select: none
	-khtml-user-select: none 
	-moz-user-select: none 
	-ms-user-select: none 
	user-select: none 

.text-left
	text-align: left

.mt-auto
	margin-top: auto !important
.mr-auto
	margin-right: auto !important
.ml-auto
	margin-left: auto !important
.ml-1
	margin-left: 1rem !important
.ml-2
	margin-left: 2rem !important
.ml-3
	margin-left: 3rem !important
.ml-4
	margin-left: 4rem !important
.mr-1
	margin-right: 1rem !important
.mr-2
	margin-right: 2rem !important
.mr-3
	margin-right: 3rem !important
.mr-4
	margin-right: 4rem !important

.ant-popover
	z-index: 2

#copyright
	position: fixed
	width: 300px
	text-align: left
	bottom: 0px
	color: rgba(255,255,255,0.4)
	font-size: 11px
	left: 15px
	bottom: 10px
	small
		color: transparent

#videoaskModalWrapper
	bottom: 15px !important
	right: 15px !important
.videoask-embed__modal_closeButton--jkIMk.videoask-embed__modal_closeButton--jkIMk.videoask-embed__modal_closeButton--jkIMk.videoask-embed__modal_closeButton--jkIMk
	display: none !important