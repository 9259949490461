#Legend
    position: absolute
    height: 100vh
    width: 100px
    left: 0px

    .Legend
        text-transform: uppercase
        text-align: left
        font-size: 40px
        transform-origin: 50% 50%

        g.positive
            text
                font-weight: bold
                text-anchor: start
                fill: #11403E
        g.neutral
            text
                font-size: 15px
                font-weight: bold
                text-anchor: start
                fill: #FFFFFF99
        g.negative
            text
                font-weight: bold
                text-anchor: end
                fill: #4F1023